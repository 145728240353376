export const fadeSliderContainer = height => ({
  height: height
});

export const scrollContainer = (isScrollable, height) => ({
  justifyContent: !isScrollable ? 'center' : '',
  height: height,
  cursor: 'grab',
  ':active': {
    cursor: 'grabbing'
  }
});

export const newsCard = (width, imageUrl) => ({
  width: width,
  background: `url('${imageUrl}') no-repeat center center/cover`
});
