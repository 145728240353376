import React, { useRef, useState, useEffect } from 'react';
import Radium from 'radium';
import { graphql, Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import get from 'lodash/get';
import concat from 'lodash/concat';
import { ImArrowRight2 as ArrowRight } from 'react-icons/im';
import ScrollContainer from 'react-indiana-drag-scroll';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FadeSlider from '../components/fade-slider';
import * as styles from '../styles/news.styles';
import { isAndAbove } from '../util';

const NewsIndex = props => {
  const newReleases = get(props, 'data.allContentfulNewRelease.nodes');
  const newsData = get(props, 'data.allContentfulNews.nodes');
  // duplicating array items (25 times) to create looping effect
  let newsPosts = [];
  for (let i = 0; i < 25; i++) {
    newsPosts = concat(newsPosts, newsData);
  }

  const scrollContainer = useRef();
  const timer = useRef();
  const [pause, setPause] = useState(false);
  const [isScrollable, setIsScrollable] = useState(true);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    scrollContainer.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    scrollContainer.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [scrollContainer]);

  useEffect(() => {
    let scrollDirection = 'right';
    timer.current = setInterval(() => {
      if (scrollContainer.current.scrollWidth > scrollContainer.current.offsetWidth) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }

      if (!pause && isScrollable && isAndAbove('md', breakpoints)) {
        if (scrollDirection === 'right') {
          scrollContainer.current.scrollLeft = scrollContainer.current.scrollLeft + 1;
          if (
            scrollContainer.current.scrollLeft + scrollContainer.current.offsetWidth ===
            scrollContainer.current.scrollWidth
          ) {
            scrollDirection = 'left';
          }
        } else if (scrollDirection === 'left') {
          scrollContainer.current.scrollLeft = scrollContainer.current.scrollLeft - 1;
          if (scrollContainer.current.scrollLeft === 0) {
            scrollDirection = 'right';
          }
        }
      }
    }, 12);
    return () => {
      clearInterval(timer.current);
    };
  }, [scrollContainer, pause, isScrollable, breakpoints]);

  const paddingTop = isAndAbove('md', breakpoints) ? 86 : 66;
  const minHeight = isAndAbove('md', breakpoints) ? '43rem' : '';
  const fadeSliderHeight = isAndAbove('md', breakpoints) ? '50%' : '80%';
  const newsCardWidth = isAndAbove('sm', breakpoints) ? 450 : 'calc(85% - 48px)';

  let cardSpacerWidth = 48;
  if (breakpoints.xs) {
    cardSpacerWidth = 24;
  } else if (breakpoints.sm) {
    cardSpacerWidth = 32;
  } else if (breakpoints.md) {
    cardSpacerWidth = 40;
  }

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title="News" />
      <div className="bg-gray-900 h-screen" style={{ paddingTop: paddingTop, minHeight: minHeight }}>
        <div className="bg-gray-200 h-full">
          <div className="p-6 sm:p-8 md:p-10 lg:p-12" style={styles.fadeSliderContainer(fadeSliderHeight)}>
            <FadeSlider slides={newReleases} height="100%" rounded />
          </div>
          <ScrollContainer
            innerRef={scrollContainer}
            className="flex pb-6 sm:pb-8 md:pb-10 lg:pb-12 bg-gray-200"
            style={styles.scrollContainer(isScrollable, '50%')}
            vertical={false}>
            <div style={{ minWidth: cardSpacerWidth }} />
            {newsPosts.map((post, index) => (
              <div
                key={index}
                style={styles.newsCard(newsCardWidth, post.image.file.url)}
                className={`flex flex-shrink-0 flex-col bg-white rounded-xl overflow-hidden relative ${
                  index !== newsPosts.length - 1 && 'mr-6'
                }`}>
                <Link
                  to={`/news/${post.slug}`}
                  className="absolute right-0 py-2 px-4 m-4 flex items-center text-white bg-gray-900 inline-block rounded text-sm sm:text-base">
                  READ MORE
                  <ArrowRight className="ml-2" />
                </Link>
                <div className="absolute bottom-0 w-full p-6 bg-gradient-to-t from-gray-900">
                  <p className="text-gray-300 text-sm sm:text-base">{post.publishedDate}</p>
                  <h1 className="text-xl sm:text-2xl font-bold text-white mt-1">{post.title}</h1>
                </div>
              </div>
            ))}
            <div style={{ minWidth: cardSpacerWidth }} />
          </ScrollContainer>
        </div>
      </div>
    </Layout>
  );
};

export default Radium(NewsIndex);

export const pageQuery = graphql`
  query NewsIndexQuery {
    allContentfulNewRelease(sort: { fields: order }) {
      nodes {
        title
        artist
        link
        image {
          file {
            url
          }
        }
      }
    }
    allContentfulNews(sort: { fields: publishedDate, order: DESC }) {
      nodes {
        title
        slug
        publishedDate(formatString: "MMMM D, YYYY")
        image {
          file {
            url
          }
        }
      }
    }
  }
`;
